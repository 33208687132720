/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');

:root {
    /* --bgColor: #232E48;
    --textColor: #fff; */
    /* --sideBgColor: #0E2238; */
    --navbarColor: #153D77;
    --sideBgColor: #fff;
    --sideTextColor: #6c757d;
    /* --sideTextColorHover: #00BAC7; */
    --sideTextColorHover: #212529;
    --sideTextColorHoverbg: #e9ecef;
    --primary: #153D77;

}

.dropdown {
    transition: all 1s linear;
}

div #sidebar-wrapper::-webkit-scrollbar {
    display: none;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Hind Siliguri', sans-serif;
    box-sizing: border-box;

}

body,
html {
    box-sizing: border-box;
}

#sidebarTopDiv {
    background: var(--navbarColor);
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 99;
    top: 0;
}

#sidebar-wrapper {
    height: 100vh;
    width: 260px;
    z-index: 1;
    background-color: var(--sideBgColor);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    margin: 0 !important;
    transition: all 0.3s linear;
}

.logo {
    display: flex;
    justify-content: space-between;
}

.logo img {
    width: 60px;
    margin-left: 10px;
}

.logo i {
    color: #fff;
    font-size: 20px;
}

hr {
    color: #fff !important;
    opacity: 1 !important;
    padding: 0 !important;
    margin-top: 5px;
}

.logowithtext {
    text-align: center;
}

.logowithtext p {
    color: var(--navbarColor);
    font-size: 15px;
}

.logowithtext h3 div {
    width: 10px;
    border-radius: 50%;
    height: 10px;
    background: green;
    display: inline-block;
}

.logowithtext h3 {
    font-size: 18px;
    color: var(--navbarColor);
}

section .logowithtext img {
    text-align: center;
    border: 1px solid #fff;
    width: 115px;
    height: 80px;
    /*border-radius: 50%;*/
    /* background: radial-gradient(#000000, white); */
    padding: 5px;
    margin-bottom: 7px;
}

.items {
    cursor: pointer;
    transition: background-image 1s;
    padding: 12px;
    border-radius: 3px;
    display: flex;
    position: relative;
    margin-bottom: 10px;
    align-items: center;

}


.items:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    right: 300px;
    bottom: 0px;
    background: var(--primary);
    opacity: 0;
    transition: all 0.5s;
    z-index: 1;
    border-radius: 3px;
}


.items h4,
.items i {
    z-index: 2;
    display: flex;
    color: var(--sideTextColor);
    display: inline-block;
}

.items h4 {
    font-size: 15px;
    font-weight: normal;
}

.items i {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: var(--primary);
}

.items:hover:after,
.active div:after {
    opacity: 1 !important;
    right: 0px !important;
}

.items:hover h4,
.items:hover i,
.active div>i,
.active div>h4 {
    color: white !important;

}

.dropicon {
    transition: all 0.5s !important;
}

/* .dropdown {
    transition: display 0.5s !important;
} */

.dropdownitems .head {
    cursor: pointer;
    transition: background-image 1s;
    padding: 12px;
    border-radius: 3px;
    display: flex;
    position: relative;
    margin-bottom: 10px;

}

.dropdown {
    margin-left: 30px;
}

.dropicon {
    display: block;
    margin-left: auto;
}

a {
    text-decoration: none !important;
}

#page-content {
    height: 100vh;
    overflow-y: scroll;
    transition: margin-left 0.3s linear;
}

#page-content .topNavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 88;
    color: #fff;
    padding: 0px 10px;
    height: 70px;
    background-color: var(--navbarColor);
    position: fixed;
    width: 100%;

}

#page-content div i {
    font-size: 25px;
}

.settingIconDiv {
    position: relative;
}

.settingDiv {
    position: absolute;
    right: 5px;
    background: rgb(255, 255, 255);
    padding: 25px 5px 10px 5px;
    border-radius: 5px;
    min-width: 170px;
    z-index: 999;
    margin-top: 10px;
    color: #3e506a;
    box-shadow: 0px 0px 10px 0;
}

.settingDiv h4 {
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 600;
    padding: 0px 10px;
}

.settingDivItem {
    display: flex;
    align-items: center;
    padding: 10px;
    transition: all 0.3s;
    border-radius: 3px;
    cursor: pointer;
}

.settingDivItem:hover {
    background: rgb(214, 211, 211);
}

.settingDivItem p {
    margin: 0;
    font-weight: 500;
}

.settingDivItem i {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.topnavUserDiv {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
}

.topnavUserDiv h5 {
    margin: 0;
    font-size: 17px;
}

.topnavUserDiv p {
    font-size: 14px;
    margin: 0;
    font-weight: 300;
    margin-top: -3px;
}

.topnavUserDiv img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}

.logoDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reminder {
    color: white;
    border-radius: 50%;
    padding: 0px 5px;
    margin-left: 13px;
    position: relative;
    z-index: 99;
    font-size: 10px;
}
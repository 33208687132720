
.list-container {
    margin-top: 0px;
    
  }
  
  .list-group-item {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #333;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 5px;
    box-shadow: 0px 0px 12px 0px #4a83de82;
    border-radius:10px !important;
  }

  .list-group-item1 {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #153D77;
    color: white;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 5px;
    box-shadow: 0px 0px 12px 0px #4a83de82;
    border-radius:10px !important;
  }
  .list1 {
    margin-top:8px;
  }
  .list1 h4{
    font-size: 18px;
    font-weight: 500;
    margin-bottom:0px;
  }
  .list1 p{
    font-size: 16px;
    color: white;
    margin-bottom: 6px;
    text-align: start;
  }
 
  
  .list-group-item.active {
    background-color: #FFFFFF !important;
    border-color:#FFFFFF !important;
    color: #0c0c0c !important;
  }
  
  .list-group-item.active h4 {
    color:#0c0c0c !important;
  } 

 .list-group-item.active p {
    color:rgb(55 55 55);
  }
  .list-group-item h3 {
    font-size: 26px;
    font-weight:600;
    margin-bottom:0px;
  }
  
  .list {
    margin-top:8px;
  }
  .list h4{
    font-size: 18px;
    font-weight: 500;
    margin-bottom:0px;
  }
  .list p{
    font-size: 16px;
    color: rgb(55 55 55);
    margin-bottom: 6px;
    text-align: start;
  }

  .content-container {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
  }
  
  @media (max-width: 768px) {
    .list-group-item {
      margin-bottom: 10px;
    }
  
    .list-group-item.active {
      border-radius: 4px;
    }
  
    .table-responsive {
      font-size: 0.9rem;
    }
  
    .btn-sm {
      font-size: 0.7rem;
      padding: 0.2rem 0.4rem;
    }
  }
  
  @media (max-width: 480px) {
    .list-group-item {
      padding: 8px 10px;
    }
  
    .content-container {
      padding: 10px;
    }
  }
  
.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button {
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-controls span {
    margin: 0 10px;
}

#paginationButton {
    width:40px;
    height:40px;
}

#paginationButton span {
    color: var(--colorBlue) !important;
}
.angle {
    margin-bottom: 20px;
    margin-left:-3px;
}

.paginationBlock {
    display: flex;
    align-items: center;
}


.paginationInput {
    display: flex;
    width: 15% !important;
    align-items: center !important;
    margin-top:7px;
}


.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
}

.paginationInput .btn {
    color:#0d6efd;
    border-color: #DEE2E6;
    font-weight: 400;
}

.paginationInput:hover .btn {
    background: #0D6EFD;
    border-color: #0D6EFD;
    color:White
}

@media (max-width:576px) {
    .paginationContainer {
        display:block
    }
    .paginationInput {
        width: 28% !important;
        
    } 
}
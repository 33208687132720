.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.623);
    z-index: 99;
    display: grid;
    place-items: center;
    top: 0;
    left: 0;
}

.spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--primary);
    border-left-color: var(--primary);
    animation: rotate 0.5s linear infinite;
    position: absolute;

}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
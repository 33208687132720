.ClipboardDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copyDiv {
    border: none;
    font-size: 13px;
    border-radius:5px;
    background:none;
}
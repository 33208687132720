.bonanza-settings {
    background: var(--bgColor);
    margin: 10px 0;
    overflow-x: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.add-record-button {
    /* margin-bottom: 20px; */
    padding: 0px 16px;
    font-size: 16px;
    background-color: #153D77;
    color: white;
    border: none;
    border-radius: 4px;
    margin-left: auto;
    display: block;
}

.add-record-button1 {
    /* margin-bottom: 20px; */
    padding: 0px 16px;
    font-size: 16px;
    background-color: #153D77;
    color: white;
    border: none;
    border-radius: 4px;
    display: block;
}

.add-record-button:hover {
    background-color: #1d509b;
}

.add-record-button1:hover {
    background-color: #1d509b;
}

.input-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin-top: 20px;
}

.input-row1 {    
    text-align: center;
}

.input-group1 {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.input-group1 label {
    font-weight: bold;
}

.input-group1 input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.status-toggle {
    display: flex;
    align-items: center;
    gap: 10px;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.status-label {
    padding: 4px 8px;
    border-radius: 4px;
    /* background-color: var(--iconColor); */
}

/* Toggle Switch Styles */
.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:checked+.slider:before {
    transform: translateX(14px);
}

input[type="checkbox"] {
    appearance: none;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 20px;
    position: relative;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

input[type="checkbox"]:checked {
    background-color: var(--primary);
}

input[type="checkbox"]::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    top: 0;
    left: 0;
    transition: transform 0.3s ease;
}

input[type="checkbox"]:checked::before {
    transform: translateX(20px);
}

/* .button-container {} */

.save-button,
.cancel-button {
    margin-left: auto;
}



/* Ensure the wrapper around the table handles overflow */
.records-table-wrapper {
    overflow-x: auto;
    margin-top: 20px;
}

/* Basic styling for the table */
.records-table {
    width: 100%;
    border-collapse: collapse;
}

.records-table th,
.records-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.records-table th {
    background-color: #f4f4f4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .records-table {
        border: 0;
        display: block;
        width: 100%;
    }

    .records-table thead {
        display: none;
    }

    .records-table tbody,
    .records-table tr,
    .records-table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    .records-table td {
        position: relative;
        padding-left: 50%;
        text-align: right;
        border: none;
        border-bottom: 1px solid #ddd;
    }

    .records-table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        font-weight: bold;
        text-align: left;
        white-space: nowrap;
        background-color: #f4f4f4;
        border-right: 1px solid #ddd;
    }

    .records-table tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
}

/* Optional: Styling for the edit button */
.records-table button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.records-table button:hover {
    background-color: #0056b3;
}

button.add-record-button {
    margin-right: 10px; 
}

.dropdown-select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}

.plan-select {
    border-radius: 5px;
    background: #153D77 !important;
}
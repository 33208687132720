
   .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .selectBox {
    width: 100%;
    background: white !important;
    color: black;
    border: 1px solid grey;
    border-radius: 5px;
  }
  
  


#filterRow div input {
    width: 100%;
    padding: 3px 10px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #ccc;
    margin: 5px 0px;
}

#filterLogo {
    margin-bottom: 5px; 
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

#filterLogo1 {
    margin-bottom: 5px; 
    cursor: pointer;    
}

.filterLogo2 {
    margin-bottom: 5px; 
    cursor: pointer;   
    display:flex;
    justify-content: space-between;
}

.filterLogo2 i {
    color: var(--primary);
}

#filterLogo1 i {
    color: var(--primary);
}

.block-btn {
    font-size: 14px !important;
    font-weight: 400 !important;
    color:white;
    padding:6px !important;
    background: #2c835a;
    border:0;
    border-radius:5px;
    margin-left:2px;
}

.helpButton {
   text-align: center;
   margin-bottom: 18px; 
   padding: 6px;
}

#filterLogo i {
    color: var(--primary);
}

#filterRow div select {
    width: 100%;
    padding: 3px 10px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #ccc;
    margin: 5px 0px;
}

#filterRow {
    background-color: #fff;
    margin: 0;
    margin-top: 10px;
    border-radius: 4px;
}

#filter i {
    color: var(--primary);
}

#filterRow div input:focus,
#filterRow div select:focus {
    outline: none !important;
    border: 1px solid var(--primary);
    box-shadow: 0 0 10px #719ECE;
}

.filter,
.reset {
    box-sizing: border-box;
}

.reset i,
.filter i {
    font-size: 10px !important;
    padding: 0 !important;
    margin: 0 !important;
}

button.reset i,
button.filter i {
    font-size: 15px !important;
}

button.reset i {
    color: var(--primary) !important;
}

#directTableRow {
    background: var(--bgColor);
    margin: 0px;
    margin-top: 10px;
    overflow-x: auto;
    margin-bottom: 10px;
}

#directTableRow table {
    width: 100%
}

#directTableRow table,
#directTableRow th,
#directTableRow td {
    border: 1px solid #ccc;
    padding: 7px;
    white-space: nowrap;
}

#directTableRow tr {
    transition: all 0.3s;
}

#directTableRow tr:hover {
    background: #224476f0;
    color: var(--bgColor) !important;
}

#directTableRow tr:hover i {
    color: var(--bgColor) !important;
}

#directTableRow th {
    background: var(--primary);
    color: var(--bgColor);
}

#directTable{
    background: var(--bgColor);
    margin-top: 10px;
    overflow-x: auto;
    margin-bottom: 10px;
}

#directTable table {
    width: 100%
}

#directTable table,
#directTable th,
#directTable td {
    border: 1px solid #ccc;
    padding: 7px;
    white-space: nowrap;
}

#directTable tr {
    transition: all 0.3s;
}

#directTable tr:hover {
    background: #224476f0;
    color: var(--bgColor) !important;
}

#directTable tr:hover i {
    color: var(--bgColor) !important;
}

#directTable th {
    background: var(--primary);
    color: var(--bgColor);
}


@media (max-width:576px) {
    #FilterMainDiv {
        height: 0px;
        position: relative;
        left: -2000px;
    }

    #filterLogo {
        text-align: right;
        float: right;
    }

    #FilterMainDiv1 {
        height: auto;
        position: relative;
        left: 0px;
        transition: 0.5s;
    }

    .filter,
    .reset {
        margin-bottom: 20px;
    }
}

.status-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
  
  .status-dot.active {
    background-color: green;
  }
  
  .status-dot.inactive {
    background-color: red;
  }

  .status-dot.open {
    background-color: #d3d300;
  }
  
  .status-dot.pending {
    background-color: red;
  }
  
  .status-dot.closed {
    background-color: green;
  }

  .checkbox {
    opacity: 0;
    position: absolute;
}

.pagePath {
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600&family=Lato:ital,wght@0,300;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Nunito:ital,wght@0,300;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Open+Sans:ital,wght@0,600;0,700;1,400;1,500;1,700&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,900;1,700&display=swap");
#logincontainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0);
  /* background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgb(0, 0, 0) 0%, var(--colorPrimary) 10%, rgba(0, 0, 0, 1) 100%); */
  /* background: url("./../../Images/loginImage.png"); */
  /* background-size: cover; */
  padding-top: 50px;
  padding-bottom: 100px;
}
.loginContent {
  border-radius: 10px;
  /* padding: 20px; */
  max-width: 420px;
  /* min-height: 100vh; */
  margin: auto;
  width: 100%;
  background: var(--containerColor);
  -webkit-user-select: none;
  user-select: none;
}

a.loginClick {
  color: white;
  font-weight: 600;
  text-decoration: none;
}

a.loginClick i {
  font-size: 10px;
}

.loginLogo img {
  width: 100%;
  max-width: 100px;
  margin: 20px auto;
  display: block;
}

.loginContent_inner {
  text-align: center;
  background: var(--containerColor);
  padding: 20px;
  border-radius: 10px;
}

.loginContent_inner h4 {
  font-size: 24px;
  margin: 0px;
  font-weight: 500;
  color: var(--textHeading);
}

.btnPrimary {
  display: block;
  margin: auto;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  padding: 10px;
  outline: none;
  border: 1px solid transparent;
  border-radius: 20px;
  background: var(--btnBackground);
  margin-bottom: 10px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  color: #000;
}

.btnPrimary:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
}

.btnSecondary {
  display: block;
  margin: auto;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  padding: 10px;
  outline: none;
  border: 1px solid var(--borderColor);
  border-radius: 20px;
  background: transparent;
  margin-bottom: 10px;
  color: var(--darkLightText);
  transition: all 0.3s;
}
.loginContent_inner p {
  margin: 5px 0px 10px 0px;
  /* text-transform: capitalize; */
  font-size: 16px;
  color: white;
}

.loginInput_inner {
  position: relative;
}

.loginInput_inner input {
  position: relative;
  width: 100%;
  padding: 8px 20px;
  padding-right: 40px;
  border-radius: 25px;
  border: 1px solid rgba(128, 128, 128, 0.192);
  outline: none;
  margin-bottom: 10px;
  background: var(--lightColor) !important;
  color: var(--textHeading);
  font-size: 14px;
}

.form-control:focus {
  border: 1px solid var(--textColor) !important;
}

.loginInput_inner i {
  position: absolute;
  right: 15px;
  top: 11px;
  color: var(--colorPrimary);
  display: flex;
}

.loginForgot_link {
  text-align: end;
  margin-bottom: 20px;
}

.loginForgot_link a {
  color: #c6c6c6;
  text-decoration: none;
  /* text-transform: capitalize; */
  font-size: small;
}

button.LoginBtn {
  padding: 10px 40px;
  border-radius: 40px;
  border: none;
  background: var(--colorPrimary);
  font-size: 16px;
}

p.sign_log {
  font-size: 12px;
  color: #9f9f9f;
}

p.sign_log a {
  color: var(--colorPrimary);
  text-decoration: none;
}

.loginSilde_img img {
  width: 58%;
  position: absolute;
  top: -20px;
  right: -20px;
  border-top-right-radius: 10px;
}

.loginSilde_img {
  position: relative;
}

.loginFooter_btn a {
  color: black;
  display: inline-block;
  width: auto;
  padding: 10px 40px;
  text-decoration: none;
  box-shadow: none;
}

#trustedLogo {
  width: 50px;
  filter: brightness(1.5);
  position: absolute;
  top: 2px;
  left: -3px;
  transform: rotate(340deg);
}

#handImage {
  width: 60%;
}

#trustShlogan {
  color: white;
  font-weight: bold;
  margin-top: 15px;
  /* text-transform: capitalize; */
  font-size: 15px;
}

@media (max-width: 1200px) {
  .loginContainer {
    top: 0;
  }
}

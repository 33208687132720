.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

.alert {
  margin-bottom: 15px;
}

button {
  margin-top: 10px;
}

p {
  font-size: 17px;
  /* color:#000000; */
  text-align: center;
  margin-top: 20px;
  font-weight:400
}

.block-btn {
  font-size: 14px;
  font-weight: 400;
  color:white;
  padding:6px;
  background: #2c835a;
  border:0;
  border-radius:5px;
  margin-left:2px;
}

.default-btn {
background-color: #0C68EF !important;
color: white !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  .btn {
    font-size: 14px;
    padding: 8px 12px;
  }

  .form-control {
    padding: 6px;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 10px;
  }

  .btn {
    font-size: 12px;
    padding: 6px 10px;
  }

  .form-control {
    padding: 5px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1000px;
  }
}
